window.smartproduktion = {
  notificationTimeout: 0,
  article_preview_status: false,

  stopDefault: function (e) {
    e.preventDefault();
  },

  string_parameterize: function (str1) {
    return str1
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/, "")
      .replace(/\s/g, "-");
  },

  removeHTML(str) {
    let doc = new DOMParser().parseFromString(str, "text/html");
    return doc.body.textContent || "";
  },

  closeEveryPopup: function () {
    Array.from($(".popup-content-helper")).forEach((item) => {
      if (!item.classList.contains("hidden")) {
        item.classList.add("hidden");
      }
    });

    document.querySelector(".popup-overlay").className = "popup-overlay";
  },

  checkIfUndefined: function (value) {
    return (
      typeof value != "undefined" &&
      value != "undefined" &&
      value != null &&
      value != "null"
    );
  },

  copyText: function (text) {
    navigator.clipboard.writeText(text);
    window.smartproduktion.showNotification(`Kopierade ${text}`);
  },

  copyTextWithoutText: function (text) {
    navigator.clipboard.writeText(text);
    window.smartproduktion.showNotification(`Kopierade`);
  },

  togglePopup: function (dataBehavior) {
    console.log(`Toggle Pioooopup: ${dataBehavior}`)

    function handleClick(e) {
      e.preventDefault();
      console.log(`Clicked the dataBehavior: ${dataBehavior}`)
      
      let content = document.querySelector( `.${dataBehavior}-content-popup` );
      // console.log(content)
      if (content != undefined && content != "NULL") {
        
        // Google Analytics Tracking & Ahoy Tracking below
        if (dataBehavior === "quotes-helper" || dataBehavior === "navigation-cart" ) {
          
          // Log popup Form 'Get Help' and 'Navigation Cart'
          ahoy.track(`Öppnat popup: '${dataBehavior}'`, window.smartproduktion.domainAndBrowserInformation);

          if (typeof ga === "function") {
            ga(
              "send",
              "event",
              "Clicks",
              `Öppnat popup: '${dataBehavior}'`
            );
          }
        }
        // Google Analytics Tracking & Ahoy Tracking below END
        $(`[data-behavior~=${dataBehavior}-open-popup]`).toggleClass("active");
        $(`.${dataBehavior}-content-popup`).toggleClass("hidden");
        $(".popup-overlay").toggleClass("hidden");
        $(".popup-overlay").toggleClass(`popup-overlay-${dataBehavior}`);
        $("body").toggleClass("stickyScroll");
      }
    }

    $(document).on("click",`[data-behavior~=${dataBehavior}-open-popup]`, handleClick);
  },

  setZipcodeCookie: function (name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },

  getZipCodeCookieAndConnect: function () {
    const zipcode = window.smartproduktion.getCookie("zipcode");
    const zipcodesInputFields = document.querySelectorAll(
      ".main-zipcode-form .ZipCodeInput"
    );

    Array.from(zipcodesInputFields).map((item) => {
      item.value = zipcode;
    });
  },

  getCookie: function (cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  goToExplorePage: function (e) {
    const ZipCodeInput = e.querySelector("input.ZipCodeInput").value;
    this.setZipcodeCookie("zipcode", ZipCodeInput, 90);

    window.location.href = `/utforska${window.location.search}`;

    // Google Analytics Tracking & Ahoy Tracking below
    let explore_track_info = `ZipcodeInput: ${ZipCodeInput}`;
    ahoy.track(explore_track_info, {
      domain: window.smartproduktion.currentDomain,
      domain_id: window.smartproduktion.domain_id,
      href: window.location.href,
      referrer: document.referrer,
    });

    if (typeof ga === "function") {
      ga("send", "event", "Clicks", explore_track_info);
    }
    // Google Analytics Tracking & Ahoy Tracking below END

    return false;
  },

  displayMessageToConsole: function (value) {
    console.log(`displayMessageToConsole: ${value}`);
  },

  hideNotification: function () {
    const container = $("[data-behavior~=notification_container]");

    container.addClass("animated-hidden");

    container.removeClass("notification-success");
    container.removeClass("shake");
    container.removeClass("notification-shake");
    container.removeClass("notification-error");
    container.removeClass("animated-visible");
  },

  showNotification: function (text, error = false) {
    console.log(`Going to show notification`)
    console.log(`text: ${text}`);
    
    if (error) {
      console.log(`error: ${error}`);
    }

    // Clear the old timeout to prevent the previous notification from hiding the new one
    clearTimeout(window.smartproduktion.notificationTimeout);
    
    // Hide the previous notification if there's one
    window.smartproduktion.hideNotification();

    // Start the process to show the new notification after a small delay to let the previous one disappear
    const container = $("[data-behavior~=notification_container]");

    if (error) {
      container.addClass("shake notification-shake notification-error");
    } else {
      container.addClass("notification-success");
    }

    $(".notification-content p").html(`${text}`);
    container.removeClass("animated-hidden");
    container.addClass("animated-visible");

    let message_time = error === true ? 7000 : 4000;

    window.smartproduktion.notificationTimeout = setTimeout(() => {
      window.smartproduktion.hideNotification();
    }, message_time);
  },

  expandAndRemove: function (toggleItem) {
    let toggle = document.querySelector(toggleItem);
    toggle.classList.remove("hidden");
  },

  // Takes in Array and check if every input field is present
  takeInArrayOfIdsToCheckIfPresent: function (element, fieldIds){
    const buttonElement = element;
    let counter = 0;

    fieldIds.forEach((id) => {
      const inputElement = document.getElementById(id);
      if (inputElement && inputElement.value) {
        counter += 1;
      }
    });

    if (fieldIds.length !== counter) {
      const errorMessageElement = document.createElement("div");
      errorMessageElement.classList.add("p-4", "m-2", "bg-red-100", "border", "font-bold", "border-radius-card");
      errorMessageElement.textContent = "Alla fält måste vara ifyllda.";
      element.parentElement.insertAdjacentElement("afterend", errorMessageElement);
    } 
    
    else {
      buttonElement.innerHTML = "<span>Sparar...</span></i>";
      
      setTimeout(() => {
        buttonElement.disabled = true;
      }, 0);

      window.smartproduktion.showNotification("Genomfört.");

      setTimeout(() => {
        location.reload();
      }, 500);
    }
  }
}


window.smartproduktion_logged_in = {
  sortMe: function () {
    // Sort images with attachement
    const findSortMeClass = document.querySelector(".sort-me");

    if (window.smartproduktion.checkIfUndefined(findSortMeClass)) {
      $(document).ready(function() {
      // Din jQuery-kod som behöver vänta på att jQuery laddas
        $(".sort-me").sortable({
        update: function (e, ui) {
          console.log(`Sortable`);
          console.log(e.target);
          ui.placeholder.height(ui.item.height());
          
          $.ajax({
            url: $(this).data("url"),
            type: "PATCH",
            data: $(this).sortable("serialize"),
            success: function (msg) {
              window.smartproduktion.showNotification("Ändrade ordningen");
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
              window.smartproduktion.showNotification(
                "Något gick fel när vi skulle ändra ordningen",
                true
              );
            },
          });
        },
      });
      });
    }
  },

  toggleDashboardSidebar: function (e) {
    const sidebar = document.querySelector(".dashboard_sidebar");
    if (window.smartproduktion.checkIfUndefined(sidebar)) {
      sidebar.classList.toggle("dashboard_sidebar_collapsed");
      document
        .querySelector(".toggle-dashboard-sidebar")
        .classList.toggle("toggle-dashboard-sidebar-collapse");

      if (sidebar.classList.contains("dashboard_sidebar_collapsed")) {
        localStorage.setItem("dashboardSidebarVisibility", false);
      } else {
        localStorage.setItem("dashboardSidebarVisibility", true);
      }
    }
  },
};

window.compare_site = {
  get_help_or_compare_companies: "manually",

  toggleBetweenHelpAndCompareFunctionality: function (toggle) {
    $(document).on("click", `[data-behavior~=${toggle}]`, function (e) {
      window.compare_site.get_help_or_compare_companies =
        e.target.dataset.compareType;

      if (window.compare_site.get_help_or_compare_companies === "help") {
        $(".instruction-tab-help").addClass("active");
        $(".instruction-tab-manually").removeClass("active");
        $(".instructions .instruction-one .instruction-content h4").html();
      } else {
        $(".instruction-tab-manually").addClass("active");
        $(".instruction-tab-help").removeClass("active");
      }
    });
  },
};


window.cartList = {
  addToCart: function (e) {
    const oldItems =
      JSON.parse(
        localStorage.getItem(
          window.smartproduktion.currentDomain + "_cartItems"
        )
      ) || [];

    // Google Analytics Tracking & Ahoy Tracking below
    // Ahoy Tracks the number of times the user has added a company to the cart
    let track_information = {
      ...window.smartproduktion.domainAndBrowserInformation,
      ...e.dataset,
    };
    let track_title = "Lagt till företag i offertkorgen";

    if (window.smartproduktion.checkIfUndefined(e.dataset.company)) {
      let companyName = JSON.parse(
        JSON.parse(e.dataset.company)["data"]
      ).name;
      let track_query = track_title + `, Företag: ${companyName}`;

      ahoy.track(track_query, track_information ? track_information : {});

      if (typeof ga === "function") {
        ga("send", "event", "Clicks", track_query);
      }
    }
    // Google Analytics Tracking & Ahoy Tracking below END

    if (
      localStorage.getItem(
        window.smartproduktion.currentDomain + "_cartItems"
      ) == null
    ) {
      // console.log(`Null is true in localStorage`)
      oldItems.push(e.dataset.company);
      this.successfullyAddedToCart(e, oldItems);
    } else {
      // // // console.log('NEW INFO')
      // // // console.log(JSON.parse(localStorage.getItem(window.smartproduktion.currentDomain + '_cartItems')))
      // // // console.log(JSON.parse(localStorage.getItem(window.smartproduktion.currentDomain + '_cartItems')).includes(e.dataset.id))
      // // // console.log($.inArray(e.dataset.id, JSON.parse(localStorage.getItem(window.smartproduktion.currentDomain + '_cartItems'))))
      const checkDatasetInArray = [];
      // // // console.log(checkDatasetInArray)
      // // // console.log('börjar här')
      const newData = JSON.parse(
        localStorage.getItem(
          window.smartproduktion.currentDomain + "_cartItems"
        )
      );

      if (newData.length != 0) {
        // console.log(`Goes here #1`)
        for (let i = 0; i < newData.length; i++) {
          // console.log(`Goes here #2`)
          let obj = JSON.parse(newData[i]);

          let datasetID = JSON.parse(e.dataset.company).id;
          if (obj["id"] == datasetID) {
            checkDatasetInArray.push(true);
          } else {
            checkDatasetInArray.push(false);
          }
        }
      }
      // console.log(`checkDatasetInArray: ${checkDatasetInArray}`)
      //
      // // // console.log(checkDatasetInArray)
      if (!checkDatasetInArray.includes(true) && oldItems.length <= 5) {
        oldItems.push(e.dataset.company);
        this.successfullyAddedToCart(e, oldItems);
      } else {
        if (oldItems.length <= 5) {
          window.smartproduktion.showNotification(
            "Företag ligger redan i offertkorg",
            true
          );
        } else {
          window.smartproduktion.showNotification(
            "Det går tyvärr inte att lägga till",
            true
          );
        }
      }
    }

    this.loadCart();
  },

  successfullyAddedToCart: function (e, oldItems) {
    // console.log(`INSIDE OF CHECK DATASETINARRAY`)
    window.smartproduktion.showNotification("Företag tillagt");

    setTimeout(function () {
      window.smartproduktion.hideNotification();
    }, 3000);

    localStorage.setItem(
      window.smartproduktion.currentDomain + "_cartItems",
      JSON.stringify(oldItems)
    );
    $("#" + e.id).addClass("success-block");
    $("#" + e.id).html("Företaget tillagt");
    $(".foretag--empty .box_" + e.id).addClass("hidden");
    $(".cart_close").removeClass("hidden");
    $(".cart_close_mobile_" + e.id).removeClass("another-hidden");
  },

  addEmptyState: function () {
    // console.log(`addEmptyState: `)
    var items = document.querySelector(
      ".cart-navigation-dropdown ul.cart-items"
    );

    if (
      window.smartproduktion.checkIfUndefined(items) &&
      items.innerText.length < 5
    ) {
      items.innerHTML = `<strong>Det verkar vara tom här.</strong> <br/><div class='small-text mt-2'>Lägg till företag och få kostnadsfria offerter.</div>`;
    }
  },

  loadButton: function (id) {
    // console.log(`loadbutton`)
    const companyId = id;
    const localStorageObj = localStorage.getItem(
      window.smartproduktion.currentDomain + "_cartItems"
    );
    if (
      window.smartproduktion.checkIfUndefined(localStorageObj) &&
      localStorageObj.length > 5
    ) {
      let local = JSON.parse(localStorageObj);
      let array = Object.entries(local).map((item) =>
        item[1].includes(`${companyId}`)
      );
      if (array.some((x) => !!x)) {
        $(`.add_${companyId}`).addClass("success-block");
        $(`.add_${companyId}`).html("Företaget tillagt");
      } else {
        $(`.add_${companyId}`).removeClass("success-block");
        $(`.add_${companyId}`).html("Lägg till företag");
      }
    }
  },

  loadCart: function (e) {
    const localStorageObj = localStorage.getItem(
      window.smartproduktion.currentDomain + "_cartItems"
    );
    var cartList = $(".cart_list");
    let howItWorks = $(".how_it_works");

    if (
      localStorage.getItem(
        window.smartproduktion.currentDomain + "_cartItems"
      )
    ) {
      if (
        JSON.parse(
          localStorage.getItem(
            window.smartproduktion.currentDomain + "_cartItems"
          )
        ).length > 0
      ) {
        // // // console.log('we access #1')
        cartList.removeClass("hidden");
        howItWorks.addClass("hidden");
      } else {
        // // // console.log('we access #2')
        howItWorks.removeClass("hidden");
        cartList.addClass("hidden");
      }
    } else {
      // // // console.log('we access #4')
      howItWorks.removeClass("hidden");
    }

    // // console.log('load local storage until #1')

    if (localStorageObj != null && localStorageObj.length > 5) {
      // console.log('load local storage until #2')
      let getCartUl = $(".cart-items");
      let getFormItems = $("#customerCompanies");
      getCartUl.html("");
      getFormItems.html("");

      JSON.parse(localStorageObj).forEach(function (element, index) {
        // console.log('load local storage until #3')
        const parseCartItems = JSON.parse(element);
        const newCartItem = JSON.parse(parseCartItems.data);
        // // console.log('localStorage.getItem( window.smartproduktion.currentDomain + '_cartItems').includes(element.name)', localStorage.getItem( window.smartproduktion.currentDomain + '_cartItems').includes(newCartItem.name))
        // // console.log(newCartItem.name)
        // console.log('local::::::::::')
        // console.log(newCartItem);
        // console.log('newCartItem')

        var getCorrectLogotype = "";

        if (newCartItem.logo_url != null && newCartItem.logo_url != "") {
          getCorrectLogotype = newCartItem.logo_url;
        } else if (newCartItem.logo_url_from_admin != null) {
          getCorrectLogotype = newCartItem.logo_url_from_admin;
        } else if (newCartItem.reco_scraped_logo != null) {
          if (
            newCartItem.reco_scraped_logo ==
            "https:/assets/images/image-missing.png"
          ) {
            getCorrectLogotype = "";
          } else {
            getCorrectLogotype = newCartItem.reco_scraped_logo;
          }
        } else if (newCartItem.source_image != null) {
          getCorrectLogotype = newCartItem.source_image;
        } else if (newCartItem.imageurls != null) {
          getCorrectLogotype = newCartItem.imageurls[0].image_url;
        } else {
          ("");
        }

        const listItem = `<li data-index='${index}' class='mb-3 listItem_${newCartItem.id} cart_listItem_${newCartItem.id}'>
            <div class='number cart_company_logo'>
              <a href='/foretag/${newCartItem.slug}'> 
              <img src='${getCorrectLogotype}' alt='${newCartItem.name}' class='overflow-hidden' />
              </a>
            </div>
            <div class='text_information cart_company_information'>
              <div class='px-3 py-1'>
                <a href='/foretag/${newCartItem.slug}'>
                  <div class='small-text font-bold mb-0 pb-0 text-color-heading'>${newCartItem.name}</div>
                </a>
                <p>${newCartItem.address}</p>
              </div>
              <div class='cart_close cursor' id='${newCartItem.id}' onclick='cartList.removeFromCart(this)'><i class='fal fa-times'></i></div>
            </div>
          </li>`;

        const formInputItems = `<div class='field' id='formInputItem_${newCartItem.id}' data-index='${index}'>
            <input type='text' name='customer[customer_companies_attributes][${index}][name]' id='customer_customer_companies_attributes_${index}_name' value='${newCartItem.name}'>
            <input type='text' name='customer[customer_companies_attributes][${index}][foretag_id]' id='customer_customer_companies_attributes_${index}_foretag_id' value='${newCartItem.id}'>
            <input type='text' name='customer[customer_companies_attributes][${index}][email]' id='customer_customer_companies_attributes_${index}_email' value='${newCartItem.email}'>
          </div>`;

        if (
          localStorage
            .getItem(window.smartproduktion.currentDomain + "_cartItems")
            .includes(newCartItem.id)
        ) {
          $(".add_" + newCartItem.id).addClass("success-block");
          $(".add_" + newCartItem.id).html("Företaget tillagt");
          $(".foretag--empty .box_" + newCartItem.id).addClass("hidden");
          $(".cart_close").removeClass("hidden");
          $(".cart_close_mobile_" + newCartItem.id).removeClass(
            "another-hidden"
          );
        }

        getFormItems.append(formInputItems);
        getCartUl.append(listItem);
      });
    }

    let getAllBoxesInEmpty = Array.from(
      document.querySelectorAll(".foretag--empty .check-if-box")
    );
    let getBox = [];
    for (let i = 0; i < getAllBoxesInEmpty.length; i++) {
      // // // console.log(getAllBoxesInEmpty[i].className);
      // // // console.log(getAllBoxesInEmpty[i].className.includes('hidden'));

      if ($(getAllBoxesInEmpty[i])) {
        getBox.push(getAllBoxesInEmpty[i].className.includes("hidden"));
      }
    }
    // // // console.log('getBox')
    // // // console.log(getBox);
    // // // console.log(!getBox.includes(false));
    if (!getBox.includes(false)) {
      $(".cart--information").addClass("hidden");
    }

    // // // console.log('check if anything?')
    if ($(".cart-items").html() != undefined && $(".cart-items").html().length < 10 ) {
      $(".selected-companies-h4").addClass("hidden");
      // // // console.log($('.cart-items').html().length)
      this.addEmptyState();
    }

    // // // console.log('How many companies?,', $('.cart--all  .cart-items li').length)

    const count_companies = $(".cart--all .cart-items li").length;
    $(".navigation-cart-open-popup span").html(count_companies);
    if (count_companies > 0) {
      $(".navigation-cart-open-popup").addClass("active-shopping-cart");
      $(".navigation-cart-open-popup span").removeClass("hidden");
    } else {
      $(".navigation-cart-open-popup").removeClass("active-shopping-cart");
      $(".navigation-cart-open-popup span").addClass("hidden");
    }
  },

  emptyTheEntireCart: function () {
    localStorage.removeItem(window.smartproduktion.currentDomain + "_cartItems");
  },

  removeFromCart: function (e) {
    const formInputItem = $("#formInputItem_" + e.id);
    const cartGetNewCartCloser = $(".cart_listItem_" + e.id);

    const getNewCartCloser = document.querySelector(".listItem_" + e.id);
    const getCartIndex = getNewCartCloser.dataset.index;
    // // // console.log('getNewCartCloser.dataset.index', getNewCartCloser.dataset.index)
    const cartItemParse = JSON.parse(
      localStorage.getItem(
        window.smartproduktion.currentDomain + "_cartItems"
      )
    );

    // Google Analytics Tracking & Ahoy Tracking below
    if (window.smartproduktion.checkIfUndefined(cartItemParse)) {
      let parsed = JSON.parse(cartItemParse[0]);
      let data = JSON.parse(parsed["data"]);

      let track_information = {
        ...window.smartproduktion.domainAndBrowserInformation,
        ...data,
      };

      let track_remove_title = `Tog bort företag från offertkorgen, Företag: ${data["name"]}`;
      ahoy.track(
        track_remove_title,
        track_information ? track_information : {}
      );

      if (typeof ga === "function") {
        ga("send", "event", "Clicks", `${track_remove_title}`);
      }
    }
    // Google Analytics Tracking & Ahoy Tracking below END

    window.cartItemParse = cartItemParse;
    cartItemParse.splice(getCartIndex, 1);
    localStorage.setItem(
      window.smartproduktion.currentDomain + "_cartItems",
      JSON.stringify(cartItemParse)
    );

    cartGetNewCartCloser.remove();
    formInputItem.remove();

    $(".add_" + e.id).removeClass("success-block");
    $(".add_" + e.id).html("Lägg till företag");
    $(".cart_close_mobile_" + e.id).addClass("another-hidden");

    getNewCartCloser.remove();

    this.loadCart();
    this.addEmptyState();
  },
};
