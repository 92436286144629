window.cookies = {

// Google Analytics
  toggleGoogleAnalytics(analyticsConsent, google_tag_id, cookie_domain_url) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      // Define default consent states
      gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
      });

      // Initialize Google Analytics with configuration
      // Moved 'gtag('js', new Date())' up to ensure it's ready for consent updates
      gtag('js', new Date());

      // Update consent for analytics storage based on user decision
      if (analyticsConsent) {
          console.log("Enabling Google Analytics storage...");
          gtag('consent', 'update', {
            'analytics_storage': 'granted',
            'ad_storage': 'granted'
          });
      } else {
          console.log("Disabling Google Analytics storage...");
          gtag('consent', 'update', {
            'analytics_storage': 'denied',
            'ad_storage': 'denied'
          });
      }

      // Configure Google Analytics only after updating consent states
      gtag('config', google_tag_id, {
          'cookie_domain': cookie_domain_url,
          'cookie_flags': 'max-age=7200;secure;samesite=none'
      });
  },


  // Toggle Google Ads (personalization, conversion tracking, etc.)
  toggleGoogleAds(adConsent, google_conversion_id, cookie_domain_url) {
    console.log(adConsent ? "Enabling Google Ads..." : "Disabling Google Ads...");

    // Ensure gtag.js is loaded and then execute consent update and config
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    
    gtag('consent', 'update', {
        'ad_storage': adConsent ? 'granted' : 'denied'
    });

    // Configure Google Ads with cookie settings after updating consent
    gtag('config', google_conversion_id, {
        'cookie_domain': cookie_domain_url,
        'cookie_flags': 'max-age=7200;secure;samesite=none'
    });
  }
}

window.checkCommonCookies = function checkCommonCookies() {
  // Define cookies related to each platform
  const cookiesToCheck = {
    'Google Analytics': ['_ga', '_gid', '_gat'],
    'Google Ads': ['_gads', '_gac_'],
    'Meta (Facebook)': ['_fbp', 'fr'],
    'LinkedIn': ['bcookie', 'lidc', 'UserMatchHistory'],
    'YouTube': ['VISITOR_INFO1_LIVE', 'YSC', 'PREF'],
    'Vimeo': ['vuid'],
    'Pinterest': ['_pinterest_sess']
  };

  // Function to check if any of the specified cookies are set
  const checkCookies = (names) => {
      return names.some(name => document.cookie.split(';').some(cookie => cookie.trim().startsWith(name + '=')));
  };

  // Check each platform's cookies and log the result
  Object.entries(cookiesToCheck).forEach(([platform, cookies]) => {
      const isPresent = checkCookies(cookies);
      if (isPresent){
        console.log(`${platform} cookies are present and set (✅)`);
      } else {
        console.log(`${platform} cookies are not present (❌)`);
      }
  });
}

window.clearCookies = function clearCookies(cookies) {
  cookies.forEach(cookieName => {
    if (typeof cookieName === 'string') {
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    } else if (cookieName instanceof RegExp) {
      // Handle regex pattern for cookie names
      document.cookie.split(';').forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
        if (cookieName.test(name)) {
          document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
      });
    }
  });
}


window.setTestCookies = function setTestCookies() {
  const cookiesToCheck = {
      'Google Analytics': ['_ga', '_gid', '_gat'],
      'Google Ads': ['_gads', '_gac_'],
      'Meta (Facebook)': ['_fbp', 'fr'],
      'LinkedIn': ['bcookie', 'lidc', 'UserMatchHistory'],
      'YouTube': ['VISITOR_INFO1_LIVE', 'YSC', 'PREF'],
      'Vimeo': ['vuid'],
      'Pinterest': ['_pinterest_sess']
  };

  const setCookie = (name, value, days) => {
      const expires = new Date(Date.now() + days * 864e5).toUTCString();
      document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  };

  Object.entries(cookiesToCheck).forEach(([provider, cookies]) => {
      cookies.forEach(cookie => {
          // Sets a generic "testvalue" for all cookies, and set them to expire in 7 days
          setCookie(cookie, 'testvalue', 7);
      });
  });

  console.log('Test cookies have been set for all listed providers.');
}

// Call the function to set the cookies
// window.setTestCookies();
window.analyticsCookies = [
  /^_ga/, // regex for Google Analytics cookies
  '_gid'  // specific Google Analytics cookie
];

window.adsCookies = [
  /^_gads/,   // regex for Google Ads cookies
  '_gac_',   // specific Google Ads cookie
  '_fbp',   // specific Meta (Facebook) cookie
  'fr',   // specific Meta (Facebook) cookie
  'bcookie',   // specific LinkedIn cookie
  'lidc',   // specific LinkedIn cookie
  'UserMatchHistory',   // specific LinkedIn cookie
  'VISITOR_INFO1_LIVE',   // specific YouTube cookie
  'YSC',   // specific YouTube cookie
  'PREF',   // specific YouTube cookie
  'vuid',   // specific Vimeo cookie
  '_pinterest_sess'   // specific Pinterest cookie
];