export const Tracking = {
  isInitialized: false,

  init: function() {
    if (this.isInitialized) return;

    const trackingElement = document.getElementById("ahoy_tracking");
    if (trackingElement) {
      
      this.domainClass = trackingElement.getAttribute("data-domain-class");
      this.domainId = trackingElement.getAttribute("data-domain-id");
      this.controllerPath = trackingElement.getAttribute("data-controller-path");
      this.actionName = trackingElement.getAttribute("data-action-name");
      this.theme = trackingElement.getAttribute("data-theme");

      console.log(
        `
          DomainClass: ${this.domainClass}, 
          DomainId: ${this.domainId}, 
          ControllerPath: ${this.controllerPath}, 
          ActionName: ${this.actionName}, 
          Theme: ${this.theme}
        `
      )

      this.setupEventListeners();
    }
    
    this.isInitialized = true;
  },

  setupEventListeners: function() {
    this.trackSpecificLink("Website", ".link-website");
    this.trackSpecificLink("Phone", ".link-phone");
    this.trackSpecificLink("Email", ".link-email");
  },

  trackSpecificLink: function(name, target) {
    // Add new event listener
    $(document).on("click", target, (e) => {
      console.log(`Click trackSpecificLink -> ${name}`);
    
      const domainObject = {
        domain_class: this.domainClass,
        controller_path: this.controllerPath,
        action_name: this.actionName,
        theme: this.theme
      }

      if ( this.domainClass === "custom_domain" ) {
        domainObject.custom_domain_id = parseInt(this.domainId)
      } else {
        domainObject.domain_id = parseInt(this.domainId)
      }

      domainObject.event_href = e.target?.href;
      domainObject.event_inner_text = e.target?.innerText;

      ahoy.track(`ClickedLink${name}`, domainObject);
    });
  }
};
