import Rails from "@rails/ujs"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

import "./src/jquery"
import "./src/jquery-ui"

import "blueimp-file-upload"

import ahoy from "ahoy.js";
window.ahoy = ahoy;

import { Tracking } from "./Tracking"
import "./SmartproduktionObjects"
import "./Cookies"


// Import the 'sortable' module from 'jquery-ui'

// Export the necessary variables, functions, or classes
$(function() {
  // Din jQuery-kod som behöver vänta på att jQuery laddas
  console.log("jQuery is loaded and DOM is readyy");
});

if (window.$) {
  console.log("jQuery has been loaded");
} else {
  console.log("jQuery has not been loaded");
}

// Check if $ and jQuery are defined
if (typeof $ !== "undefined") {
  console.log("$ is defined");
} else {
  console.log("$ is not defined");
}

if (typeof jQuery !== "undefined") {
  console.log("jQuery is defined");
} else {
  console.log("jQuery is not defined");
}

Rails.start()

/**
 * Disables Turbo for selected forms.
 * Sets the 'data-turbo' attribute to 'false' for forms that do not have the 'html-form' class,
 * and sets the 'data-remote' attribute to 'true' for all forms.
//  */
function disableTurboForSelectedForms() {
  // Deklarera variabler här för att hålla dem inom funktionens scope
  const formSelector = 'form';

  // Funktion för att inaktivera Turbo på specifika formulär
  const disableTurbo = () => {
    document.querySelectorAll(formSelector).forEach(form => {
      if (!form.classList.contains('html-form')) {
        form.setAttribute('data-turbo', 'false');
        form.setAttribute('data-remote', 'true');
      } else {
        form.setAttribute('data-turbo', 'false');
      }
    });
  };

  // Lyssna på Turbo-laddning för att säkerställa att DOM är helt laddad
  disableTurbo()
}

/**
 * Checks if the application is running in development mode and sets the window.developmentMode flag accordingly.
 */
function developmentModeOrNot(){
  const body = document.querySelector('body');
  const developmentMode = body.dataset.developmentMode;

  if (developmentMode === "true") {
    window.developmentMode = true;
  }
}

// This is for turbolinks and react

/**
 * Adds click event listeners to links with the 'cold-refresh' class
 * and redirects to the link's href attribute when clicked.
 */
function turboReactColdRefresh() {
  const currentPath = window.location.pathname; // Hämta den nuvarande sökvägen
  const links = document.querySelectorAll(`a[href="${currentPath}"]`); // Välj alla ankartaggar med href som matchar den nuvarande sökvägen
  
  links.forEach((link) => {
    link.setAttribute('data-turbo', 'false'); // Inaktivera Turbo för dessa länkar
  });
}


/**
 * Sets the active link by adding the "active" class to the link that matches the current page's URL.
*/
function setActiveLink() {
  console.log(`DOMContentLoaded -> setActiveLink()`)
  
  const currentPath = window.location.pathname;

  // If the current path is the root path, return early
  if (currentPath === "/") {
    return;
  }

  // Select all links in the document
  const links = document.querySelectorAll('a');

  // Iterate over each link
  links.forEach(link => {
    // Compare the link's href attribute (path) with the current page's path
    if (!link.getAttribute('href')){
      return
    }

    let href = link.getAttribute('href');
    
    // Remove everything before the `/` in the URL
    const origin = window.location.origin;
    const path = href.replace(origin, '');

    if(path === currentPath || path === currentPath + "/") {
      link.classList.add('active');
    }
  });
}

/**
 * Initializes the DOM manipulation components.
 * This function sets up event listeners and behaviors for various popup and toggle components.
 */

const manipulatePopupClose = () =>{
  $(".dialog").on("click", function(e){
    if (
      !$(e.target).hasClass("dialog-contents") && 
      !$(e.target).closest(".dialog-contents").length
    ) {
      $(this).addClass("hidden");
    }
  })
}

const openPopupOverlay = (target) => {
  if (!target){
    return
  }
  console.log(`Försöker ÖPPNA popup: ${target}`)
  
  $(".popup-overlay").data(`target`, `${target}`);
  $(".popup-overlay").removeClass("hidden");
  $("body").addClass("stickyScroll");
}

const closePopupOverlay = (target) => {
  $(".popup-overlay").data(`target`, ``);
  $(".popup-overlay").addClass("hidden");
  $("body").removeClass("stickyScroll");
  
  if (!target){
    return
  }
  
  console.log(`Försöker STÄNGA popup: ${target}`)

  $(`.${target}-content-popup`).addClass("hidden");

}

function initializePopupComponents() {
  console.log(`---------------------------- initializePopupComponents --------------------------------`)

  $(`[data-popup-behavior]`).on("click", function(e){
    console.log(`data-popup-behavior: ${e.target}`)
    e.preventDefault();
    
    console.log(`Data POPUP behavior: ${e.target}`)

    const behavior = $(this).data('popup-behavior');
    
    if (!behavior.includes("-open-popup")){
      return;
    }

    const target = behavior.replace("-open-popup", "")

    // Google Analytics Tracking & Ahoy Tracking below  
    if(target === "quotes-helper" || target === "navigation-cart"){
      // Log popup Form "Get Help" and "Navigation Cart"
      ahoy.track(`Öppnat popup: '${target}'`, window.smartproduktion.domainAndBrowserInformation);  
      
      if(typeof ga === "function"){
        ga('send', 'event', 'Clicks', `Öppnat popup: '${target}'`);
      }
    }
    
    $(`.${target}-content-popup`).toggleClass("hidden");  

    openPopupOverlay(target)
  });

  $(document).on("click", ".popup-overlay, .manipulate-close-popup-overlay", function(e){
    e.preventDefault();
    
    const target = $(this).data('target');

    if (window.location.href.includes(`popupCustomer=`)) {
      const url = window.location.href.split(`?popupCustomer`)[0];
      window.history.pushState({}, "", url);
    }

    closePopupOverlay(target)
  })
}

function initalizeToggleNavigation(){
  console.log(`---------------------------- initalizeToggleNavigation --------------------------------`)
  
  $(`[data-icon-behavior]`).on("click", function(e){
    console.log(`data-icon-behavior: ${e.target}`)
    
    $(`#${e.target.id}`).toggleClass("active");
    
    
    if (!e.target.parentElement.classList.contains("pointer-events-none")){
      e.preventDefault();
    }

    if (e.target.parentElement.href)  {
      const parent = e.target.parentElement
      window.location = parent.href;
      
      // If target link is blank open new tab
      if (parent.target == "_blank"){
        window.open(parent.href);
      }
      
      return
    }
    
    let dataBehavior = $(this).data('icon-behavior');
    if (!dataBehavior.includes("-open")){
      return
    }
  
    dataBehavior = dataBehavior.replace("-open", "");
    let adminNavigationItems = document.querySelectorAll( "#top-admin-nav #admin-navigation-items svg.admin-navigation-item" );

    Array.from(adminNavigationItems).map((item) => {
      let behaviorID = item.dataset["iconBehavior"].replace(/-open/g, "");
      if (behaviorID != dataBehavior){
        $(`[data-icon-behavior~=${behaviorID}-open]`).removeClass("active");
        $(`#${behaviorID}-content`).addClass("hidden");
      }
    });

    $(`[data-behavior~=${dataBehavior}-open]`).toggleClass("active");
    $(`#${dataBehavior}-content`).toggleClass("hidden");
  });
}

function initalizeToggleComponent(){  
  console.log("---------------------------- initalizeToggleComponent --------------------------------")

  $(`[data-behavior]`).on("click", function (e) {
    console.log(`data-behavior: ${e.target}`)
    let dataBehavior = $(this).data('behavior');      
    
    if (!dataBehavior.includes("toggle-")){
      return
    } 

    if (dataBehavior.startsWith("toggle-")) {
      $(e.target).closest("[data-behavior]").toggleClass("toggle-active");
      let new_toggle = dataBehavior.replace("toggle-", "");
      $(`[data-open-behavior~=${new_toggle}]`).toggleClass("hidden");
      $(`[data-open-behavior~=${dataBehavior}]`).toggleClass("hidden");
    } 
    
    else {
      $(e.target).closest("[data-behavior]").remove();
      $(e.target).closest("[data-behavior]").toggleClass("toggle-active");
      $(`[data-open-behavior~=${dataBehavior}]`).toggleClass("hidden");
    }
  });
}

// This clicks one toggle and open another toggle
function simpleComponentToggle() {
  $("[data-simple-toggle]").on("click", function() { // Use function(){} to maintain $(this) context
    $(this).toggleClass("toggle-active");  
    let dataSimpleToggle = $(this).data("simple-toggle"); // Use jQuery .data() to access data attributes
    $(`#${dataSimpleToggle}`).toggleClass("hidden");
  });
}

// Turbo Load Events 
document.addEventListener("DOMContentLoaded", () => {
  console.log(`Application.js -> DOMContentLoaded`)
  Tracking.init();

  // Anropa funktionen för att utföra funktionaliteten
  simpleComponentToggle()
  initializePopupComponents();
  initalizeToggleNavigation();
  initalizeToggleComponent();
  manipulatePopupClose();
  setActiveLink();
  developmentModeOrNot();
  disableTurboForSelectedForms();

  // Make it accessible in the window object
  window.initializePopupComponents = initializePopupComponents;
  window.openPopupOverlay = openPopupOverlay;
});
